import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import rich from "../images/rich.jpg"
import { Container } from "react-bootstrap"

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <Container className="singleCol">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy applies to information collected online from users of this website. In this policy, you can learn what kind of information we collect, when and how we might use that information, how we protect the information, and the choices you have with respect to your personal information.
      </p>
      <h2>What personal information is collected through this website and how is it used?</h2>
      <p>
        We collect information about our users in three ways: directly from the user, from our Web server logs and through cookies. We use the information primarily to provide you with a personalized Internet experience that delivers the information, resources, and services that are most relevant and helpful to you. We don’t share any of the information you provide with others, unless we say so in this Privacy Policy, or when we believe in good faith that the law requires it.
      </p>
      <h2>
        User-supplied information
      </h2>
      <p>
      If you fill out the “contact” form on this website, we will ask you to provide some personal information (such as e-mail address, name, phone number and state). We only require that you provide an e-mail address on the contact form. Further, if chat is available through this site, you may be asked to provide information if you participate in an online chat. Please do not submit any confidential, proprietary or sensitive personally identifiable information (e.g. Social Security Number; date of birth; drivers license number; or credit card, bank account or other financial information) (collectively, “Sensitive Information”). If you submit any Sensitive Information, you do so at your own risk and we will not be liable to you or responsible for consequences of your submission.
      </p>

      <h2>Web server logs</h2>

      <p>When you visit our website, we may track information about your visit and store that information in web server logs, which are records of the activities on our sites. The servers automatically capture and save the information electronically. Examples of the information we may collect include:</p>
      <ul>
        <li>your unique Internet protocol address;</li>
        <li>the name of your unique Internet service provider;</li>
        <li>the town/city, county/state and country from which you access our website;</li>
        <li>the kind of browser or computer you use;</li>
        <li>the number of links you click within the site;</li>
        <li>the date and time of your visit;</li>
        <li>the web page from which you arrived to our site;</li>
        <li>the pages you viewed on the site; and</li>
        <li>certain searches/queries that you conducted via our website(s).</li>
      </ul>

      <p>
        The information we collect in web server logs helps us administer the site, analyze its usage, protect the website and its content from inappropriate use and improve the user’s experience.
      </p>

      <h2>Cookies</h2>

      <p>
        In order to offer and provide a customized and personal service, our websites and applications may use cookies and similar technologies to store and help track information about you. Cookies are simply small pieces of data that are sent to your browser from a Web server and stored on your computer’s hard drive. We use cookies to help remind us who you are and to help you navigate our sites during your visits. Cookies also can tell us where visitors go on a website and allow us to save preferences for you so you won’t have to re-enter them each time you visit. The use of cookies is relatively standard. Most Internet browsers are initially set up to accept cookies, but you can use your browser to either notify you when you receive a cookie or to disable cookies.
      </p>
      <p>
        If you wish to disable cookies from this site, you can do so using your browser. You should understand that some features of many sites may not function properly if you don’t accept cookies. For more information about using browsers to manage cookies, please see <a href="https://www.allaboutcookies.org/verify">All About Cookies</a>. You can opt out of Google’s use of cookies by visiting <a href="https://adssettings.google.com/authenticated">Google’s Ad Settings</a>.
      </p>

      <p>By visiting this website, you consent to the use of cookies and similar technologies in accordance with this Privacy Statement.</p>

      <h2>California Do Not Track</h2>

      <p>Our web services do not alter, change, or respond upon receiving Do Not Track (DNT) requests or signals in browsers. As described in more detail above, we track user activity using web server logs, cookies and similar technologies. Information collected in web server logs helps us analyze website usage and improve the user’s experience. Cookies allow us to offer you a customized experience and present relevant advertising to you.</p>

      <h2>
        How is personal information protected?
      </h2>

      <p>
        We take certain appropriate security measures to help protect your personal information from accidental loss and from unauthorized access, use or disclosure. However, we cannot guarantee that unauthorized persons will always be unable to defeat our security measures.
      </p>

      <hr />
      <p>If you have any additional questions or concerns about this privacy policy, please contact us via the phone number, contact form or mailing address listed on this website. If our information practices change in a significant way, we will post the policy changes here.</p>
      <p>April, 2022</p>

    </Container>
  </Layout>
)

export default PrivacyPolicy
